<template>
    <div class="door_home-content">
        <div class="door_toolbar">
            <div class="door_toolbar_item">
                <div class="door_toolbar_item_icon" @click="toDeviceView">
                    <i class="el-icon-monitor" style="font-size: 50px;color: white"></i>
                </div>
                <div class="door_toolbar_item_content" @click="toDeviceView">
                    <div style="font-size: 25px">设备数量</div>
                    <div style="margin-top: 20px">{{showTitle(1)}}</div>
                </div>
            </div>
            <div class="door_toolbar_item1">
                <div class="door_toolbar_item_icon1" @click="toPersonView">
                    <i class="el-icon-user-solid" style="font-size: 50px;color: white"></i>
                </div>
                <div class="door_toolbar_item_content" @click="toPersonView">
                    <div style="font-size: 25px">人员数量</div>
                    <div style="margin-top: 20px">{{showTitle(2)}}</div>
                </div>
            </div>
            <div class="door_toolbar_item2">
                <div class="door_toolbar_item_icon2" @click="getAllDeviceListOpenBoxData(0)">
                    <i class="el-icon-box" style="font-size: 50px;color: white"></i>
                </div>
                <div class="door_toolbar_item_content" @click="getAllDeviceListOpenBoxData(0)">
                    <div style="font-size: 25px">开箱总数</div>
                    <div style="margin-top: 20px">{{showTitle(3)}}</div>
                </div>
            </div>
            <div class="door_toolbar_item3">
                <div class="door_toolbar_item_icon3" @click="getAllDeviceListOpenBoxData(1)">
                    <i class="el-icon-loading" style="font-size: 50px;color: white"></i>
                </div>
                <div class="door_toolbar_item_content" @click="getAllDeviceListOpenBoxData(1)">
                    <div style="font-size: 25px">今日开箱</div>
                    <div style="margin-top: 20px">{{showTitle(4)}}</div>
                </div>
            </div>
        </div>

        <div class="door_data-content">
            <div class="door_record">
                <el-table :data="recordData" height="100%" :row-class-name="tableRowClassName"
                          v-loading="loading" filters="">
                    <el-table-column align="center" prop="fileUrl" label="抓拍照片" width="80">
                        <template scope="scope">
                            <el-image style="width: 50px;height: 50px;" :src="scope.row.fileUrl"
                                      @click="showImage(scope.row.fileUrl)"/>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="姓名" width="150">
                    </el-table-column>
                    <el-table-column align="center" prop="result" label="结果" width="150">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.type===0?'success':'danger'" disable-transitions>
                                {{scope.row.result}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="" label="其它" width="200">
                        <template slot-scope="scope">
                            <el-popover trigger="hover" placement="top">
                                <p>身份证: {{ scope.row.idCard }}</p>
                                <p>IC卡: {{ scope.row.icCard }}</p>
                                <p>结果: {{ scope.row.result }}</p>
                                <p>分数: {{ scope.row.score }}</p>
                                <p>设备名称: {{ scope.row.doorDevice.name }}</p>
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">查看</el-tag>
                                </div>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="date" label="日期" width="200">
                    </el-table-column>
                </el-table>
            </div>
            <div class="door_states">
                <div id="myChart" style="width: 100%;height: 100%"></div>
            </div>

        </div>
        <el-dialog title="查看照片" :visible.sync="showImageDialogVisible" width="20%" :before-close="handleClose"
                   :modal-append-to-body="false">
			<span>
				<el-image :src="dialogPersonFileUrl"></el-image>
			</span>
            <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showImageDialogVisible = false">确 定</el-button>
			</span>
        </el-dialog>


        <el-dialog :title="openBoxTitle" :visible.sync="showAllDeviceOpenBoxDialogVisible" width="30%"
                   :before-close="handleClose"
                   :modal-append-to-body="false">
            <span style="display: flex;flex-direction: column;width: 100%;height: 500px">
                <el-table ref="filterTable" :data="allDeviceOpenBoxData" height="100%"
                          v-loading="allDeviceOpenBoxDataLoading">
                  <el-table-column
                          label="序号"
                          align="center"
                          type="index"
                          width="100">
                </el-table-column>
                <el-table-column align="center" prop="name" label="设备名称" width="auto">
                </el-table-column>
                <el-table-column align="center" prop="toDayOpenBoxCount" label="开箱次数" width="auto">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>
                            {{scope.row.toDayOpenBoxCount}}
                        </el-tag>
                    </template>
                </el-table-column>

            </el-table>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                recordData: [],
                deviceCount: 0,
                recordCount: 0,
                personCount: 0,
                todayCount: 0,
                visitorCount: 0,
                loading: false,
                title: [],
                showImageDialogVisible: false,
                page: 1,
                length: 20,
                dialogPersonFileUrl: '',
                gg: ['gg', 'aa'],
                allDeviceOpenBoxData: [],
                showAllDeviceOpenBoxDialogVisible: false,
                allDeviceOpenBoxDataLoading: false,
                openBoxTitle:'开箱总数'
            }
        },
        mounted() {
            this.findAWeekState()
            this.findPersonCount()
            this.findTodayCount()
            this.findDeviceCount()
            this.loadData()
            setInterval(() => {
                var path = this.$route.path
                if (path === '/door_home') {
                    this.getRecordCount()
                } else {
                    clearInterval(this)
                }
            }, 5000)
        },
        methods: {
            handleClose(done) {
                this.dialogPersonFileUrl = ''
                done()
            },
            handlerSwitch(index) {
                if (index === 1) {
                    this.getRecordCount()
                }
            },
            errorHandler() {
                return true
            },
            //绘制表格
            tableRowClassName({
                                  row,
                                  rowIndex
                              }) {
                // if (row.success) {
                // 	return 'success-row'
                // } else {
                // 	return 'warning-row'
                // }
            },
            //绘制统计图
            drawLine(data) {
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '',
                    },
                    tooltip: {},
                    xAxis: {
                        data: [
                            '星期一',
                            '星期二',
                            '星期三',
                            '星期四',
                            '星期五',
                            '星期六',
                            '星期天',
                        ],
                    },
                    yAxis: {},
                    series: [{
                        name: '刷脸',
                        type: 'bar',
                        data: data,
                    },],
                })
            },
            getRecordCount() {
                setTimeout(() => {
                    axios.get('/doorRecord/count')
                        .then((res) => {
                            if (res.data.code === 1) {
                                if (this.recordCount !== res.data.data.count) {
                                    this.loadData()
                                    this.findAWeekState()
                                    this.findPersonCount()
                                    this.findTodayCount()
                                    this.findDeviceCount()
                                }
                            }
                        })
                })
            },
            //加载数据
            loadData() {
                this.loading = true
                setTimeout(() => {
                    axios.post('/doorRecord/pagingQuery', {
                        page: this.page,
                        length: this.length,
                    }).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.recordCount = res.data.data.totalElements
                            this.recordData = res.data.data.content
                        }
                    }).catch((error) => {
                        this.loading = false
                    })

                })
            },
            // 获取当前星期刷脸统计
            findAWeekState() {
                setTimeout((res) => {
                    axios.get('/doorRecord/findAWeekState').then((res) => {
                        if (res.data.code === 1) {
                            this.drawLine(res.data.data)
                        }
                    })
                })
            },
            //设备数量
            findDeviceCount() {
                setTimeout(() => {
                    axios.get('/doorDevice/count').then((res) => {
                        this.deviceCount = res.data.data.count
                    })
                })
            },
            //人员数量
            findPersonCount() {
                setTimeout((res) => {
                    axios.get('/doorPerson/count').then((res) => {
                        this.personCount = res.data.data.count
                    })
                })
            },
            //今日刷脸记录总数
            findTodayCount() {
                setTimeout((res) => {
                    axios.get('/doorRecord/findTodayCount').then((res) => {
                        this.todayCount = res.data.data.count
                    })
                })
            },
            //今日访客记录总数
            findVisitorCount() {

            },
            showTitle(index) {
                if (index === 1) {
                    return this.deviceCount
                }
                if (index === 2) {
                    return this.personCount
                }
                if (index === 3) {
                    return this.recordCount
                }
                if (index === 4) {
                    return this.todayCount
                }
                return ''
            },
            showImage(fileUrl) {
                this.dialogPersonFileUrl = fileUrl
                this.showImageDialogVisible = true
            },
            toDeviceView() {
                this.$router.push('door_device')
            },
            toPersonView() {
                this.$router.push('door_person')
            },

            getAllDeviceListOpenBoxData(type) {
                this.allDeviceOpenBoxDataLoading = true
                this.showAllDeviceOpenBoxDialogVisible = true
                if (type === 1) {
                    this.openBoxTitle='今日开箱'
                    setTimeout(() => {
                        axios.post('/doorDevice/findAllToDayDeviceRecordCount')
                            .then((res) => {
                                this.allDeviceOpenBoxDataLoading = false
                                if (res.data.code === 1) {
                                    this.allDeviceOpenBoxData = res.data.data
                                }
                            })
                            .catch((error) => {
                                this.allDeviceOpenBoxDataLoading = false
                            })
                    })
                } else {
                    this.openBoxTitle='开箱总数'
                    setTimeout(() => {
                        axios.post('/doorDevice/findAllDeviceRecordCount')
                            .then((res) => {
                                this.allDeviceOpenBoxDataLoading = false
                                if (res.data.code === 1) {
                                    this.allDeviceOpenBoxData = res.data.data
                                }
                            })
                            .catch((error) => {
                                this.allDeviceOpenBoxDataLoading = false
                            })
                    })
                }

            }
        },
    }
</script>

<style>
    .door_home-content {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .door_toolbar {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 95%;
        height: 130px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .door_toolbar_item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_toolbar_item1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        flex: 1;
        margin-left: 20px;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_toolbar_item2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        margin-left: 20px;
        flex: 1;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_toolbar_item3 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 100%;
        margin-left: 20px;
        flex: 1;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_toolbar_item_icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #9FB95A;
    }


    .door_toolbar_item_icon1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #3a8ee6;
    }

    .door_toolbar_item_icon2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #64D572;
    }

    .door_toolbar_item_icon3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 40%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: #F25E43;
    }


    .door_toolbar_item_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 70%;
        font-size: 20px;
    }


    .door_data-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 95%;
        flex: 1;
        margin-bottom: 20px;
    }

    .door_record {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin-right: 10px;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

    .door_states {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        flex: 1;
        height: 100%;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }

</style>
